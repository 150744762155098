.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1rem;
}
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  padding: 0.5rem;
  font-size: 1rem;
}
.react-datepicker__header {
  background-color: #f8fafc;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #3b82f6;
  color: white;
}
.react-datepicker__day--today {
  font-weight: bold;
  color: #3b82f6;
}
